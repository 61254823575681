<template>
  <router-view></router-view>
</template>

<script>

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import url('https://zap-shop.ru/skins/fonts/fonts.css');

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --mobile-nav-height: 50px;
  --primary-color: #000;
  --body-bg: #edeff1;
  --primary-blocks-bg: #fff;
  --primary-blocks-shadow: 0 0 1px 0 #000;
  --main-nav-bg: #22282c;
  --main-nav-active-btn-bg: #0d1013;
  --main-nav-nested-bg: #151a1d;
  --mobile-nav-bg: #fff;
  --mobile-nav-color: #000;
  --mobile-nav-box-shadow: 0 0 6px -2px rgba(26, 26, 26, 0.8);
  --loader-color: #fff;
  --btn-loader-color: #fff;
  --pl-loader-bg: rgba(255, 255, 255, 0.4);
  --btn-pl-loader-bg: rgba(255, 255, 255, 0.4);
  --input-br: 8px;
  --button-bg: #333;
  --button-hover-bg: #000;
  --button-color: #fff;
  --button-disabled-color: #c2c2c2;
  --button-disabled-bg: #838383;
  --field-bg: #fff;
  --field-border-color: rgba(127, 127, 127, 0.3);
  --field-focus-border-color: rgba(0, 0, 0, 0.4);
  --field-focus-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.5);
  --field-disabled-color: #a6a6a6;
  --select-field-tip: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4=');
  --switcher-bg: #fbfbfb;
  --switcher-activated-bg: #333;
  --switcher-disabled-bg: #e9e9e9;
  --switcher-activated-disabled-bg: #646464;
  --grey-color: #65686c;
  --light-grey-color: #979ca4;
  --dark-grey-color: #3d3d3d;
  --success-color: #3b9359;
  --error-color: #ea2027;
  --warning-color: #ee5a24;
  --error-bg: #efe6e8;
  --primary-gap: 15px;
  --primary-br: 15px;
  --secondary-br: 10px;
  --primary-margin: 15px;
  --table-border: 1px solid rgba(0, 0, 0, 0.2);
  --primary-contrast-bg: #efefef;
  --primary-contrast-color: var(--primary-color);
}
body.dark-theme {
  --primary-color: #fff;
  --body-bg: #363738;
  --primary-blocks-bg: #222;
  --primary-blocks-shadow: 0 0 1px 0 rgba(240, 246, 255, 0.3);
  --main-nav-bg: #1d1d1d;
  --main-nav-active-btn-bg: #141414;
  --main-nav-nested-bg: #0e0e0e;
  --mobile-nav-bg: rgba(26, 26, 26, 0.8);
  --mobile-nav-color: #fff;
  --mobile-nav-box-shadow: 0 0 6px -2px rgba(240, 246, 255, 0.3);
  --loader-color: #333;
  --btn-loader-color: #272727;
  --pl-loader-bg: rgba(29, 29, 29, 0.2);
  --btn-pl-loader-bg: rgba(29, 29, 29, 0.1);
  --button-bg: #e2e2e2;
  --button-hover-bg: #fff;
  --button-color: #000;
  --button-disabled-color: #9c9c9c;
  --button-disabled-bg: #686868;
  --field-bg: #292929;
  --field-focus-border-color: rgba(255, 255, 255, 0.4);
  --field-focus-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.5);
  --field-disabled-color: #ababab;
  --select-field-tip: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiIGZpbGw9IiNjY2MiLz48L3N2Zz4=');
  --switcher-bg: #2a2a2a;
  --switcher-activated-bg: #1a1a1a;
  --switcher-disabled-bg: #3c3c3c;
  --switcher-activated-disabled-bg: #2f2f2f;
  --grey-color: #a4a9b0;
  --light-grey-color: #777b81;
  --dark-grey-color: #c6c6c6;
  --error-bg: #5f5757;
  --table-border: 1px solid rgba(255, 255, 255, 0.1);
  --primary-contrast-bg: #0e0e0e;
  --primary-contrast-color: var(--primary-color);
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--body-bg);
}
body {
  font-size: 14px;
  overflow: hidden !important; /* prevent UIPopup overflow: visible */
  color: var(--primary-color);
  direction: ltr;
  font-weight: normal;
}
body, textarea, input, select {
  font-family: 'Roboto', Arial, Helvetica, Tahoma, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  display: flex;
  height: 100%;
}
@media (max-width: 767px) {
  ol.breadcrumbs {
    font-size: 14px !important;
  }
}
@media (max-width: 499px) {
  ol.breadcrumbs {
    font-size: 13px !important;
  }
}
a, a:hover, a:visited {
  color: inherit;
}
a:hover {
  text-decoration: none;
}
textarea,
input,
select {
  color: var(--primary-color);
  border: 1px solid var(--field-border-color);
  padding: 26px 8px 6px;
  background: var(--field-bg);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
textarea:focus,
input:focus,
select:focus {
  outline: none;
  border-color: var(--field-focus-border-color);
  box-shadow: var(--field-focus-box-shadow);
}
textarea:disabled,
input:disabled,
select:disabled {
  color: var(--field-disabled-color);
}
textarea:disabled ~ label,
input:disabled ~ label,
select:disabled ~ label {
  color: var(--field-disabled-color);
}
select {
  background-image: var(--select-field-tip);
  background-repeat: no-repeat;
  background-position: 99% 50%;
  background-size: 17px;
}
textarea,
input,
select,
button {
  border-radius: var(--input-br);
  font-size: 15px;
}
textarea,
input,
select,
form button {
  flex-grow: 1;
  width: 100%;
}
button {
  border: 0;
  padding: 15px;
  cursor: pointer;
  color: var(--button-color);
  transition: all 100ms linear;
  position: relative;
  overflow: hidden;
  font-weight: normal; /* prevent submit button bold in iOS 18 safari */
  background: var(--button-bg);
}
button:not(.btn-pl-loader):not(.btn-disabled):hover {
  background: var(--button-hover-bg);
  transform: scale(1.015);
}
button:not(.btn-pl-loader):not(.btn-disabled):active {
  transform: scale(0.985);
}
button.btn-disabled {
  background: var(--button-disabled-bg);
  color: var(--button-disabled-color);
  cursor: not-allowed;
}
button.sm-button {
  padding: 10px;
}
button.xs-button {
  padding: 6px;
}
button.inline-button {
  min-width: 0;
}
form {
  display: flex;
  flex-direction: column;
  gap: var(--primary-gap);
}
.form-fieldset {
  display: flex;
  flex-direction: column;
  gap: var(--primary-gap);
  position: relative;
  border: 0;
  padding: 0;
  margin: 0;
}
.form-fieldset legend {
  color: var(--primary-color);
  padding: 0;
  margin: 0 0 var(--primary-gap);
  font-weight: bold;
  font-size: 1.2em;
}
.form-fieldset-list .form-field-wrap {
  padding-right: 28px;
}
.form-field-wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.form-field-wrap button {
  flex-grow: unset;
  width: auto;
  min-width: 0;
}
.form-field-wrap label {
  position: absolute;
  margin-top: 17px;
  left: 8px;
  right: 8px;
  transition: all 100ms ease;
}
.fields-bulk-label,
.form-field-wrap label {
  color: var(--dark-grey-color);
}
.form-field-error label,
.form-field-wrap input:focus ~ label,
.form-field-wrap textarea:focus ~ label,
.form-field-wrap select ~ label,
.form-field-wrap-filled textarea ~ label,
.form-field-wrap-filled input ~ label {
  font-size: 12px;
  margin-top: 8px !important;
}
.form-field-error input,
.form-field-error textarea,
.form-field-error select {
  border: 1px solid var(--error-color);
  background-color: var(--error-bg);
}
.form-field-wrap-required label::after,
.form-field-of-line-wrap-required label::after,
.form-field-wrap-required .fields-bulk-label::after,
.form-field-wrap-required .radio-buttons-label::after {
  content: '*';
  opacity: 0.7;
  display: inline-block;
  padding-left: 5px;
}
.form-field-wrap-required .radio-buttons label::after {
  display: none;
}
.form-field-errors {
  color: var(--error-color);
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.form-remove-field-from-fieldset-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 28px;
  z-index: 1;
}
.form-fieldset-hidden,
.form-field-hidden {
  display: none;
}
.buttons-wrap {
  display: flex;
  gap: var(--primary-gap);
}
.icon-buttons {
  display: flex;
  gap: 5px;
}
.radio-buttons {
  min-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--dark-grey-color);
}
.radio-buttons label {
  position: relative;
  display: flex;
  margin: 0 !important;
  left: auto;
  top: auto;
  right: auto;
  gap: 5px;
  cursor: pointer;
  color: var(--primary-color);
}
.radio-buttons input {
  position: absolute;
  opacity: 0;
  overflow: hidden;
}
.radio-buttons .radio-circle {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #fff;
  box-shadow: var(--primary-blocks-shadow);
  cursor: pointer;
  margin-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-buttons .radio-circle::before {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 2px;
  transition: all 100ms ease;
}
.radio-buttons input:checked ~ .radio-circle::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #333;
}
/* SWITCHERS */
.switcher-wrap {
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: center;
}
label.switcher {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  min-width: 50px;
  max-width: 50px;
  height: 28px;
  margin: 0 !important;
}
.switcher input {
  position: absolute;
  opacity: 0;
  overflow: hidden;
}
.switcher-checkbox-name-wrap {
  margin-right: auto;
}
.switcher-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--switcher-bg);
  box-shadow: 0 0 0 1px var(--field-border-color);
  border-radius: 14px;
}
.switcher-slider::before {
  position: absolute;
  content: "O";
  text-align: center;
  line-height: 25px;
  font-family: Arial, serif;
  font-size: 13px;
  color: #909090;
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background: #fbfbfb;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
  0 1px 0 rgba(0, 0, 0, 0.1);

  border-radius: 12px;
}
.switcher input:checked + .switcher-slider {
  background: var(--switcher-activated-bg);
}
input:disabled + .switcher-slider {
  background: var(--switcher-disabled-bg);
  cursor: not-allowed;
}
input:checked:disabled + .switcher-slider,
.switcher-wrap-disabled-enabled .switcher-slider {
  background: var(--switcher-activated-disabled-bg) !important;
}
.switcher-slider,
.switcher-slider::before {
  transition: all .2s ease;
}
.switcher input:checked + .switcher-slider::before,
.switcher-wrap-disabled-enabled .switcher-slider::before {
  transform: translateX(22px);
  content: "I";
}
.switcher:active input:not(:disabled) + .switcher-slider::before {
  width: 33px;
}
.switcher:active input:not(:disabled):checked + .switcher-slider::before {
  transform: translateX(13px);
}
.switcher-desc {
  font-size: 90%;
  color: var(--grey-color);
}
.switcher-wrap-disabled .switcher-checkbox-name-wrap {
  color: var(--grey-color);
}
.switcher-wrap-disabled .switcher-desc {
  color: var(--light-grey-color);
}
/* SWITCHERS */
.icon-buttons span {
  font-size: 17px;
  color: var(--grey-color);
  transition: all 150ms ease;
  cursor: pointer;
}
.icon-buttons span:hover {
  color: var(--primary-color);
  transform: scale(1.1);
}
/* LOADER */
.pl-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pl-loader::after,
button.btn-pl-loader::after {
  width: 48px;
  height: 48px;
  border: 5px solid var(--loader-color);
  border-radius: 50%;
  display: inline-block;
  animation: pl-loader-rotation 1s linear infinite;
}
button.btn-pl-loader::after {
  border-color: var(--btn-loader-color);
}
.pl-loader-dark::after {
  border-color: #7b7b7b;
}
.pl-loader::after,
.pl-loader-dark::after,
button.btn-pl-loader::after {
  border-bottom-color: transparent;
}
@keyframes pl-loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
button.btn-pl-loader {
  color: rgba(255, 255, 255, 0);
}
.pl-loader::before,
.pl-loader::after,
button.btn-pl-loader::before,
button.btn-pl-loader::after {
  content: '';
}
.pl-loader,
button.btn-pl-loader::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: var(--pl-loader-bg);
}
button.btn-pl-loader::before {
  background: var(--btn-pl-loader-bg);
}
button.btn-pl-loader::after {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  z-index: 2;
}
/* LOADER END */
.m-primary {
  margin: var(--primary-margin);
}
.mt-primary {
  margin-top: var(--primary-margin);
}
.mr-primary {
  margin-right: var(--primary-margin);
}
.mb-primary {
  margin-bottom: var(--primary-margin);
}
.ml-primary {
  margin-left: var(--primary-margin);
}
.info-blocks {
  display: flex;
  flex-direction: column;
  gap: var(--primary-gap);
}
.info-block {
  border-radius: var(--primary-br);
  background: var(--primary-blocks-bg);
  box-shadow: var(--primary-blocks-shadow);
  padding: var(--primary-gap);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--primary-gap);
}
.info-block ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.info-block li {
  list-style-type: square;
  list-style-position: inside;
}
.info-block .icon-buttons {
  position: absolute;
  top: var(--primary-gap);
  right: var(--primary-gap);
}
.info-block h2,
.info-block h3,
.info-block h4,
.info-block h5 {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.info-block h3 span[class^="icomoon-icon-"],
.info-block h3 span[class*=" icomoon-icon-"] {
  font-size: 80%;
  color: var(--dark-grey-color);
}
.info-block-error-marked {
  border-left: 3px solid var(--error-color);
}
.info-block-warning-marked {
  border-left: 3px solid var(--warning-color);
}
.info-block-success-marked {
  border-left: 3px solid var(--success-color);
}
.page-small {
  max-width: 600px;
}
.page-middle {
  max-width: 900px;
}
/* СКРЫТИЕ СТАНДАРТНЫХ СКРОЛЛБАРОВ */
.hidden_scroll_overflow {
  position: relative;
  overflow: hidden;
}
.hidden_scroll_outer,
.hidden_horizontal_scroll_outer {
  position: absolute;
  box-sizing: content-box;
}
.hidden_scroll_outer {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 50px;
}
.hidden_horizontal_scroll_outer {
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 50px;
}
.hidden_scroll_overflow,
.hidden_scroll_outer,
.hidden_horizontal_scroll_outer,
.hidden_scroll_inner {
  width: inherit;
  height: inherit;
  min-height: inherit;
  max-height: inherit;
}
.hidden_scroll_overflow {
  width: 100%;
}
/* СКРЫТИЕ СТАНДАРТНЫХ СКРОЛЛБАРОВ */
/* ФОРМАТИРОВАНИЕ ТЕКСТА */
.ta-c {
  text-align: center;
}
.ta-r {
  text-align: right;
}
.fs80p {
  font-size: 80%;
}
.fs85p {
  font-size: 85%;
}
.fs90p {
  font-size: 90%;
}
.fs95p {
  font-size: 95%;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.fwb {
  font-weight: bold;
}
.error-color {
  color: var(--error-color);
}
.warning-color {
  color: var(--warning-color);
}
.success-color {
  color: var(--success-color);
}
.grey-color {
  color: var(--grey-color);
}
.dark-grey-color {
  color: var(--dark-grey-color);
}
/* ФОРМАТИРОВАНИЕ ТЕКСТА */
@media (min-width: 400px) {
  body {
    font-size: 16px;
  }
  button {
    min-width: 180px;
  }
  .form-field-wrap label {
    margin-top: 16px;
  }
}
</style>