<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_pricelist_info>
        <div>
          Вы уверены, что хотите удалить прайс-лист <b>{{ pricelistName }}</b>?
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Form from "@/components/UI/Form/Form.vue";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const pricelistId = parseInt(this.$route.params.pricelistId)
    const goToPricelistsList = () => {
      this.$router.push({ name: 'pricelists.all' })
    }
    const successCallback = (response) => {
      goToPricelistsList()
    }
    const cancelCallback = (response) => {
      goToPricelistsList()
    }

    const formBuilder = new FormBuilder()

    formBuilder
        .addFieldSetField('delete_pricelist_info', 'delete_pricelist_info', {
          slot: 'delete_pricelist_info',
          type: 'infoblock'
        })

    const pageHeader = 'Удаление прайс-листа'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      pricelistId: pricelistId,
      apiMethod: `pricelists/${pricelistId}/delete`,
      pricelistName: ''
    }
  },
  methods: {
    async getPricelist() {
      const successCallback = (response) => {
        if (response.data?.pricelist) {
          const pricelist = response.data.pricelist

          this.pricelistName = pricelist?.name ? pricelist.name : 'Без названия'
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelist()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>