<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-small">
    <div class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({ name: 'companies.add' })">
          Добавить компанию
        </button>
      </div>
      <div v-for="company in companies ?? []" class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'companies.current.edit', params: { companyId: company.id } })"
                class="icomoon-icon-pencil"
          ></span>
          <span @click="this.$router.push({ name: 'companies.current.delete', params: { companyId: company.id } })"
              class="icomoon-icon-bin"
          ></span>
        </div>
        <h3>
          {{ company.company_user_type }} {{ company.company_name }}
        </h3>
        <div>
          ИНН: {{ company.tin }}
        </div>
      </div>
      <div v-if="null !== companies && !companies.length">
        Компании не добавлены
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs},
  data() {
    const pageHeader = 'Компании'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader
      },
      companies: null
    }
  },
  methods: {
    async getCompanies() {
      const successCallback = (response) => {
        this.companies = response.data?.companies ?? []
      }

      await apiTransport({
        apiMethod: 'companies',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getCompanies()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>