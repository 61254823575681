<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_markups_config_info>
        <div>
          Вы уверены, что хотите удалить наценки для группы цен <b>{{ pricesGroupName }}</b>?
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const pricelistId = parseInt(this.$route.params.pricelistId)
    const markupsConfigId = parseInt(this.$route.params.markupsConfigId)

    const goToMarkupsConfigs = () => {
      this.$router.push({ name: 'pricelists.current.markups_configs.all' })
    }
    const successCallback = (response) => {
      goToMarkupsConfigs()
    }
    const cancelCallback = (response) => {
      goToMarkupsConfigs()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_markups_config_info', 'delete_markups_config_info', {
          slot: 'delete_markups_config_info',
          type: 'infoblock'
        })

    const pageHeader = 'Удаление наценок'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      pricelistId: pricelistId,
      markupsConfigId: markupsConfigId,
      apiMethod: `pricelists/${pricelistId}/markups/${markupsConfigId}/delete`,
      pricesGroupName: ''
    }
  },
  methods: {
    async getMarkupsConfig() {
      const successCallback = (response) => {
        if (response.data?.markups_config) {
          this.pricesGroupName = response.data.markups_config.prices_group.name
        }

        const pricelistName = response.data.pricelist_main_info?.name ?? null

        this.breadcrumbsConfig.routes.push({
          name: 'pricelists.current',
          params: { pricelistId: this.pricelistId },
          title: pricelistName ? `Прайс-лист «${pricelistName}»` : 'Новый прайс-лист'
        })

        this.breadcrumbsConfig.routes.push({
          name: 'pricelists.current.markups_configs.all',
          params: { pricelistId: this.pricelistId },
          title: 'Наценки'
        })
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/markups/${this.markupsConfigId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getMarkupsConfig()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>