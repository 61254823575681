<template>
  <div class="breadcrumbs">
    <template v-for="route in handledRoutes">
      <router-link :to="route.to">
        {{ route.title }}
      </router-link>
      <span>»</span>
    </template>
    <div>
      {{ config.currentTitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object
  },
  data() {
    return {
      handledRoutes: this.getHandledRoutes(this.config)
    }
  },
  methods: {
    getHandledRoutes(config) {
      const handledRoutes = [
        {
          to: {
            name: 'summary',
            params: {}
          },
          title: 'Главная'
        }
      ]

      for (let key in config?.routes ?? []) {
        handledRoutes.push({
          to: {
            name: config.routes[key].name,
            params: config.routes[key]?.params ?? {}
          },
          title: config.routes[key].title
        })
      }

      return handledRoutes
    }
  },
  watch: {
    config: {
      handler(newValue) {
        this.handledRoutes = this.getHandledRoutes(newValue)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: var(--primary-gap);
}
</style>