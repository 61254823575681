<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-small">
    <div v-if="null !== feed" class="info-blocks">
      <div v-if="!isFullyCreated" class="error-color">
        Настройка не завершена – генерация невозможна
      </div>
      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasMainInfo
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.main', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-file-text2"></span> Основная информация
        </h3>
        <div v-if="!feed.main_info.is_disabled">
          <span class="icomoon-icon-switch success-color"></span> Фид включен
        </div>
        <div v-if="feed.main_info.is_disabled">
          <span class="icomoon-icon-switch error-color"></span> Фид выключен
        </div>
        <div>
          {{ formatLabel }}
          <br />
          <span class="grey-color">Филиал:</span> {{ feed.main_info.branch_office?.name ?? 'не указан' }}
        </div>
        <div class="last-event-info-container">
          <div v-if="null === lastGenerationActionEventId"
               class="last-event-info"
          >
            Фид ни разу не генерировался
          </div>
          <div v-else class="last-event-info"
                :class="{
                  'last-event-info-success': isLastGenerationActionEventSuccess,
                  'last-event-info-error': !isLastGenerationActionEventSuccess
                }"
          >
            <div v-if="isLastGenerationActionEventSuccess">
              Сгенерирован <b>{{ lastGenerationActionEventDatetime?.formatted }}</b>
            </div>
            <div v-else>
              Ошибка генерации <b>{{ lastGenerationActionEventDatetime?.formatted }}</b>
            </div>
            <button class="xs-button"
                    @click="this.$router.push({
                      name: 'events.current',
                      params: {
                        eventId: lastGenerationActionEventId
                      }
                    })"
            >
              Отчет
            </button>
          </div>
        </div>
        <div v-if="null !== feedContent.generated_at">
          <span class="grey-color">
            Сгенерировано предложений:
          </span> <b>{{ feedContent.count }}</b>
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !feed.feed_urls.length
           }"
      >
        <h3>
          <span class="icomoon-icon-link"></span> Ссылки на фиды
        </h3>

        <div v-if="feed.main_info.is_disabled" class="error-color">
          Ссылки доступны только для включенных прайс-листов
        </div>
        <div v-else-if="!feed.feed_urls.length" class="error-color">
          Ссылки появятся после генерации
        </div>
        <template v-else v-for="feedUrl in feed.feed_urls">
          <a :href="feedUrl" target="_blank" class="text-ellipsis">
            {{ feedUrl }}
          </a>
        </template>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasOffersConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.offers_config', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-cogs"></span> Настройки предложений
        </h3>
        <div v-if="hasOffersConfig">
          <span class="grey-color">Группа цен:</span> {{ feed.offers_config.prices_group?.name ?? 'не установлена' }}
          <br />
          <span class="grey-color">Цены:</span> от {{ feed.offers_config.min_price }} до {{ feed.offers_config.max_price }}
          <br />
          <span class="grey-color">Количество предложений:</span> {{ feed.offers_config.max_count ?? 'не ограничено' }}
        </div>
        <div v-else class="error-color">
          Не настроено
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasPricelistsConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.pricelists', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-files-empty"></span> Прайс-листы
        </h3>
        <div v-if="hasPricelistsConfig">
          {{ feed.pricelists_count }} прайс-лист(а/ов)
        </div>
        <div v-else class="error-color">
          Прайс-листы не выбраны
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasScheduleConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.schedule', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-calendar"></span> Расписание генерации
        </h3>
        <div v-if="hasScheduleConfig">
          {{ feed.per_week_generations_count }} раз(а) в неделю
        </div>
        <div v-else class="error-color">
          Расписание не настроено
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import {HttpStatusCode} from "axios";
import "@/pages/Platform/Events/last_event.css";

export default {
  components: {Breadcrumbs},
  data() {
    const feedId = this.$route.params?.feedId ?? null

    return {
      pageHeader: '',
      breadcrumbsConfig: {
        currentTitle: '',
        routes: [
          {
            name: 'feeds.all',
            title: 'Фиды'
          }
        ]
      },
      feedId: feedId,
      feed: null,
      feedContent: null,
      lastGenerationActionEventId: null,
      isLastGenerationActionEventSuccess: false,
      lastGenerationActionEventDatetime: null,
      isFullyCreated: false,
      formatLabel: 'Формат фида не выбран',
      hasMainInfo: false,
      hasOffersConfig: false,
      hasScheduleConfig: false,
      hasPricelistsConfig: false,
      isFeedContentPollingActive: false
    }
  },
  methods: {
    async getFeed() {
      const successCallback = (response) => {
        this.feed = response.data.feed_summary

        this.feedContent = this.feed.content

        this.isFullyCreated = this.feed.is_fully_created
        this.hasMainInfo = this.feed.has_main_info
        this.hasOffersConfig = this.feed.has_offers_config
        this.hasScheduleConfig = this.feed.has_schedule_config
        this.hasPricelistsConfig = this.feed.has_pricelists_config

        if (this.hasMainInfo) {
          this.formatLabel = this.feed.main_info.format.label
        }

        this.pageHeader = this.feed.main_info?.name ? `Фид «${this.feed.main_info.name}»` : 'Новый фид'
        this.breadcrumbsConfig.currentTitle = this.pageHeader

        this.setLastGenerationActionData(this.feed.last_generation_action_event)

        // Enable polling, if feed enabled
        this.isFeedContentPollingActive = this.isFullyCreated && !this.feed.main_info.is_disabled

        this.updateFeedSummaryContent()
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/summary`,
        successCallback: successCallback
      })
    },
    async getFeedSummaryContentUpdate() {
      const successCallback = (response) => {
        this.feedContent = response.data?.feed_summary_content
        this.setLastGenerationActionData(response.data?.last_generation_action_event)

        if (this.isFeedContentPollingActive && this.isFullyCreated && !this.feed.main_info.is_disabled) {
          this.updateFeedSummaryContent()
        }
      }

      // Don't use finallyCallback for check JWT expired 401 response
      const errorCallback = (err) => {
        // Don't call on 401 response! It prevents to multiply timeout calls
        if (this.isFeedContentPollingActive && HttpStatusCode.Unauthorized !== err.status) {
          this.updateFeedSummaryContent()
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/summary/content`,
        successCallback: successCallback,
        errorCallback: errorCallback
      })
    },
    setLastGenerationActionData(event) {
      this.lastGenerationActionEventId = event?.id ?? null
      this.isLastGenerationActionEventSuccess = 'success' === event?.status
      this.lastGenerationActionEventDatetime = event?.datetime ?? null
    },
    updateFeedSummaryContent() {
      setTimeout(() => {
        this.getFeedSummaryContentUpdate()
      }, 5000)
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getFeed()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ],
  beforeUnmount() {
    // Disable polling is important
    this.isFeedContentPollingActive = false
  }
}
</script>

<style scoped>

</style>