<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const pricelistId = this.$route.params.pricelistId

    const goToPricelistSummary = () => {
      this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelistId } })
    }

    const successCallback = (response) => {
      goToPricelistSummary()
    }
    const cancelCallback = (response) => {
      goToPricelistSummary()
    }

    const addFilter = () => {
      this.formBuilder.addFieldSetField(
          'filters',
          'filters',
          this.createFilterField(),
          true
      )
    }

    const addQuantityReplacementRule = () => {
      this.formBuilder.addFieldSetFieldsLine(
          'quantity_replacements',
          'quantity_replacements',
          this.createQuantityReplacementRuleFieldsOfLine(),
          true
      )
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('min_price', 'min_price', {
          label: 'Загружать позиции с ценами от',
          type: 'number',
          required: true
        })
        .addFieldSetField('max_price', 'max_price', {
          label: 'Загружать позиции с ценами до',
          type: 'number',
          required: true
        })
        .addFieldSetField('add_filters', 'add_filters', {
          label: 'Исключать строки по правилам',
          description: 'Регистр не имеет значения',
          type: 'switcher',
          mapErrorsOfFieldNames: ['filters']
        })
        .addFieldSetParams('filters', {
          buttonAfter: {
            buttonText: 'Добавить ограничение',
            click: addFilter
          },
          isHidden: () => {
            return this.isFiltersDisabled()
          }
        })
        .addFieldSetField('add_quantity_replacements', 'add_quantity_replacements', {
          label: 'Заменять количество по правилам',
          description: 'Регистр не имеет значения',
          type: 'switcher',
          mapErrorsOfFieldNames: ['quantity_replacements']
        })
        .addFieldSetParams('quantity_replacements', {
          buttonAfter: {
            buttonText: 'Добавить правило',
            click: addQuantityReplacementRule
          },
          isHidden: () => {
            return this.isQuantityReplacementsDisabled()
          }
        })

    const pageHeader = 'Фильтрация и автозамена'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `pricelists/${pricelistId}/filters/update`,
      pricelistId: pricelistId
    }
  },
  methods: {
    isFiltersDisabled() {
      return !this.formBuilder.getField('add_filters')?.isEnabled
    },
    isQuantityReplacementsDisabled() {
      return !this.formBuilder.getField('add_quantity_replacements')?.isEnabled
    },
    // Сallback for prevent using link to the same object
    createFilterField(value) {
      return {
        label: 'Подстрока для исключения позиции',
        type: 'text',
        required: true,
        value: value ?? '',
        max_length: 100
      }
    },
    // Сallback for prevent using link to the same object
    createQuantityReplacementRuleFieldsOfLine(fromValue, toValue) {
      return {
        type: 'text_fields_line',
        listFieldName: 'quantity_replacements',
        fields: [
          {
            fieldName: 'replace_from',
            label: 'Если указано',
            type: 'text',
            required: true,
            value: fromValue ?? '',
            max_length: 20
          },
          {
            fieldName: 'replace_to',
            label: 'То заменить на',
            type: 'number',
            required: true,
            value: toValue ?? '',
            max_length: 20
          }
        ]
      }
    },
    async getPricelistFilters() {
      const successCallback = (response) => {
        if (response.data?.pricelist_filters) {
          const pricelist_filters = response.data.pricelist_filters

          this.formBuilder.updateFieldParam('min_price', 'value', pricelist_filters.min_price)
          this.formBuilder.updateFieldParam('max_price', 'value', pricelist_filters.max_price)

          const filters = pricelist_filters?.filters ?? []
          const quantityReplacements = pricelist_filters?.quantity_replacements ?? []

          for (let key in filters) {
            let filterField = this.createFilterField(filters[key])

            this.formBuilder.addFieldSetField('filters', 'filters', filterField, true)
          }

          if (filters.length) {
            this.formBuilder.updateFieldParam('add_filters', 'isEnabled', true)
          }

          for (let key in quantityReplacements) {
            let configFieldsOfLine = this.createQuantityReplacementRuleFieldsOfLine(
                quantityReplacements[key]?.replace_from,
                quantityReplacements[key]?.replace_to
            )

            this.formBuilder.addFieldSetFieldsLine(
                'quantity_replacements',
                'quantity_replacements',
                configFieldsOfLine,
                true
            )
          }

          if (quantityReplacements.length) {
            this.formBuilder.updateFieldParam('add_quantity_replacements', 'isEnabled', true)
          }
        }

        const pricelistName = response.data.pricelist_main_info?.name ?? null

        this.breadcrumbsConfig.routes.push({
          name: 'pricelists.current',
          params: { pricelistId: this.pricelistId },
          title: pricelistName ? `Прайс-лист «${pricelistName}»` : 'Новый прайс-лист'
        })
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/filters`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelistFilters()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>