<template>
  <div class="http-code-page">
    <h1>
      Страница не найдена
    </h1>
    <div class="http-code-wrap">
      404
    </div>
    <button @click="$router.push({
      name: 'main'
    })">
      На главную
    </button>
  </div>
</template>

<script>
export default {

}
</script>
<style scoped>
.http-code-page {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.http-code-wrap {
  font-size: 72px;
  font-weight: bold;
}
</style>