<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const branchOfficeId = this.$route.params?.branchOfficeId ?? null
    const isCreate = null === branchOfficeId
    const successCallback = (response) => {
      this.$router.push({ name: 'branchoffices.all' })
    }
    const cancelCallback = (response) => {
      this.$router.push({ name: 'branchoffices.all' })
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('branch_office_config', 'company_id', {
          label: 'Компания',
          type: 'select',
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          required: true
        })
        .addFieldSetField('branch_office_config', 'name', {
          label: 'Название филиала',
          type: 'text',
          required: true
        })
        .addFieldSetField('branch_office_config', 'timezone_id', {
          label: 'Часовой пояс филиала',
          type: 'select',
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          required: true
        })

    const pageHeader = isCreate ? 'Новый филиал' : 'Редактирование филиала'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'branchoffices.all',
            title: 'Филиалы'
          }
        ]
      },
      isCreate: isCreate,
      submitButtonText: isCreate ? 'Создать' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      branchOfficeId: branchOfficeId,
      apiMethod: isCreate ? 'branch-offices/create' : 'branch-offices/' + branchOfficeId + '/update',
    }
  },
  methods: {
    async getBranchOffice() {
      const successCallback = (response) => {
        if (response.data?.branch_office) {
          for (let fieldName in response.data.branch_office) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', response.data.branch_office[fieldName])
            }
          }

          // Map company id
          this.formBuilder.updateFieldParam(
              'company_id',
              'value',
              response.data.branch_office.company?.id
          )

          // Map timezone id
          this.formBuilder.updateFieldParam(
              'timezone_id',
              'value',
              response.data.branch_office.timezone.id
          )
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices/' + this.branchOfficeId,
        successCallback: successCallback
      })
    },
    async getCompanies() {
      const successCallback = (response) => {
        if (response.data?.companies) {
          // Company not required – add empty option first
          this.formBuilder.addSelectOption('company_id', {
            value: null,
            text: 'Не выбрана'
          })

          for (let key in response.data.companies) {
            let company = response.data.companies[key]
            let companyName = company?.company_user_type + ' ' + company?.company_name

            this.formBuilder.addSelectOption('company_id', {
              value: company?.id,
              text: companyName
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'companies',
        successCallback: successCallback
      })
    },
    async getTimezones() {
      const successCallback = (response) => {
        if (response.data?.timezones) {
          for (let key in response.data.timezones) {
            let timezone = response.data.timezones[key]

            this.formBuilder.addSelectOption('timezone_id', {
              value: timezone.id,
              text: `${timezone.user_name} (UTC ${timezone.offset.user_formatted})`
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'dictionary/timezones',
        queryParams: {
          include: ['Europe', 'Asia']
        },
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    if (!this.isCreate) {
      this.$emit('startLoading')
    }
  },
  mounted() {
    // Fetch companies and timezones first important for mapping
    Promise.all([this.getCompanies(), this.getTimezones()])
        .then(async () => {
          if (!this.isCreate) {
            await this.getBranchOffice()
          }
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>