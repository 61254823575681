<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:branch_office_is_not_set_info>
        <div>
          <b>Для прайс-листа не указан филиал</b>
        </div>
        <div>
          Группы цен для прайс-листа могут быть выбраны только из того же филиала, что и прайс-лист, поэтому сначала необходимо указать филиал прайс-листа.
        </div>
        <div>
          <button @click="this.$router.push({ name: 'pricelists.current.edit.main', params: {pricelistId: pricelistId} })">
            Указать филиал прайс-листа
          </button>
        </div>
      </template>
      <template v-slot:branch_office_no_prices_groups_info>
        <div>
          <b>Для филиала прайс-листа не настроено ни одной группы цен</b>
        </div>
        <div>
          Группы цен для прайс-листа могут быть выбраны только из того же филиала, что и прайс-лист, поэтому сначала добавьте группы цен для филиала «<b>{{ pricelistBranchOffice.name }}</b>», к которому привязан данный прайс-лист.
        </div>
        <div>
          <button @click="this.$router.push({ name: 'pricesgroups.all' })">
            Перейти к группам цен
          </button>
        </div>
      </template>
      <template v-slot:pricelist_no_unsed_prices_groups_info>
        <div>
          <b>Для всех групп цен уже настроены наценки</b>
        </div>
        <div>
          Для каждой группы цен можно добавить наценки только один раз для одного прайс-листа.
        </div>
        <div>
          Вы можете изменить их или удалить, но не добавить повторно.
        </div>
        <div>
          <button @click="this.goToMarkupsConfigs()">
            Перейти к настроенным наценкам
          </button>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const pricelistId = this.$route.params.pricelistId
    const markupsConfigId = this.$route.params?.markupsConfigId ?? null
    const isCreate = null === markupsConfigId

    const successCallback = (response) => {
      this.goToMarkupsConfigs()
    }
    const cancelCallback = (response) => {
      this.goToMarkupsConfigs()
    }

    const addDiapasonMarkupFieldsLine = () => {
      this.formBuilder.addFieldSetFieldsLine(
          'diapasons_markups',
          'diapasons_markups',
          this.createDiapasonsMarkupsFieldsLine(),
          true
      )
    }

    const addBrandMarkupFieldsLine = () => {
      this.formBuilder.addFieldSetFieldsLine(
          'brands_markups',
          'brands_markups',
          this.createBrandsMarkupsFieldsLine(),
          true
      )
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .setSubmitDisabledCallback(() => {
          return !this.isEditAllowed()
        })
        .addFieldSetField('markups_config', 'prices_group_id', {
          label: 'Группа цен',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return !isCreate
          }
        })
        .addFieldSetField('markups_config', 'out_of_diapasons_markup', {
          label: 'Общая наценка, %',
          type: 'number',
          required: true
        })
        .addFieldSetField('all_diapasons_markups', 'all_diapasons_markups', {
          label: 'Наценки по диапазонам цен',
          type: 'label'
        })
        .addFieldSetParams('diapasons_markups', {
          buttonAfter: {
            buttonText: 'Добавить наценку по диапазону цен',
            click: addDiapasonMarkupFieldsLine
          }
        })
        .addFieldSetField('all_brands_markups', 'all_brands_markups', {
          label: 'Корректировка наценок на бренды',
          type: 'label'
        })
        .addFieldSetParams('brands_markups', {
          buttonAfter: {
            buttonText: 'Добавить корректировку на бренд',
            click: addBrandMarkupFieldsLine
          }
        })
        .addFieldSetParams([
            'markups_config',
            'brands_markups',
            'diapasons_markups',
            'all_brands_markups',
            'all_diapasons_markups'
          ], {
          isHidden: () => {
            return !this.isEditAllowed()
          }
        })

    const pageHeader = 'Настройка наценок'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      isCreate: isCreate,
      hasPricesGroups: null, // null by default for prevent disable submit before prices groups fetched
      hasUnusedPricesGroups: null, // null by default for prevent disable submit before prices groups fetched
      pricelistBranchOffice: null, // null by default for prevent disable submit before pricelist main info fetched
      submitButtonText: isCreate ? 'Добавить' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      pricelistId: pricelistId,
      markupsConfigId: markupsConfigId,
      apiMethod: isCreate ? `pricelists/${pricelistId}/markups/create` : `pricelists/${pricelistId}/markups/${markupsConfigId}/update`,
    }
  },
  methods: {
    goToMarkupsConfigs() {
      this.$router.push({
        name: 'pricelists.current.markups_configs.all',
        params: {
          pricelistId: this.pricelistId
        }
      })
    },
    isEditAllowed() {
      return null !== this.pricelistBranchOffice
          && this.hasPricesGroups
          && (this.hasUnusedPricesGroups || !this.isCreate)
    },
    // Сallback for prevent using link to the same object
    createDiapasonsMarkupsFieldsLine(from, to, markup) {
      return {
        type: 'text_fields_line',
        listFieldName: 'diapasons_markups',
        mapErrorsFieldName: 'all_diapasons_markups',
        fields: [
          {
            fieldName: 'from',
            label: 'Цена от',
            type: 'number',
            required: true,
            value: from ?? '',
            max_length: 12
          },
          {
            fieldName: 'to',
            label: 'Цена до',
            type: 'number',
            required: true,
            value: to ?? '',
            max_length: 12
          },
          {
            fieldName: 'markup',
            label: 'Наценка, %',
            type: 'number',
            required: true,
            value: markup ?? '',
            max_length: 7
          }
        ]
      }
    },
    // Сallback for prevent using link to the same object
    createBrandsMarkupsFieldsLine(brandName, markup) {
      return {
        type: 'text_fields_line',
        listFieldName: 'brands_markups',
        mapErrorsFieldName: 'all_brands_markups',
        fields: [
          {
            fieldName: 'brand_name',
            label: 'Бренд',
            type: 'text',
            required: true,
            value: brandName ?? '',
            max_length: 60
          },
          {
            fieldName: 'markup',
            label: 'Корректировка, %',
            type: 'number',
            required: true,
            value: markup ?? '',
            max_length: 7
          }
        ]
      }
    },
    async getMarkupsGroup() {
      const successCallback = (response) => {
        const markupsConfig = response.data.markups_config
        const markups = markupsConfig.markups
        const diapasonsMarkups = markups.diapasons_markups ?? []
        const brandsMarkups = markups.brands_markups ?? []

        for (let fieldName in markupsConfig) {
          if (this.formBuilder.hasField(fieldName)) {
            this.formBuilder.updateFieldParam(fieldName, 'value', markupsConfig[fieldName])
          }
        }

        this.formBuilder.updateFieldParam('prices_group_id', 'value', markupsConfig.prices_group.id)
        this.formBuilder.updateFieldParam('out_of_diapasons_markup', 'value', markups.out_of_diapasons_markup)

        for (let key in diapasonsMarkups) {
          let configFieldsOfLine = this.createDiapasonsMarkupsFieldsLine(
              diapasonsMarkups[key]?.from,
              diapasonsMarkups[key]?.to,
              diapasonsMarkups[key]?.markup
          )

          this.formBuilder.addFieldSetFieldsLine(
              'diapasons_markups',
              'diapasons_markups',
              configFieldsOfLine,
              true
          )
        }

        for (let key in brandsMarkups) {
          let configFieldsOfLine = this.createBrandsMarkupsFieldsLine(
              brandsMarkups[key]?.brand_name,
              brandsMarkups[key]?.markup
          )

          this.formBuilder.addFieldSetFieldsLine(
              'brands_markups',
              'brands_markups',
              configFieldsOfLine,
              true
          )
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/markups/${this.markupsConfigId}`,
        successCallback: successCallback
      })
    },
    async getPricelistAvailablePricesGroups() {
      const successCallback = (response) => {
        const pricesGroups = response.data.prices_groups ?? []
        this.pricelistBranchOffice = response.data.pricelist_main_info.branch_office

        this.hasPricesGroups = pricesGroups.length > 0

        if (this.hasPricesGroups) {
          for (let key in pricesGroups) {
            let pricesGroup = pricesGroups[key]

            let optionText = pricesGroup.name
            if (this.isCreate && pricesGroup.is_used_for_pricelist) {
              optionText += ' (наценки уже настроены)'
            }

            this.formBuilder.addSelectOption('prices_group_id', {
              value: pricesGroup.id,
              text: optionText,
              disabled: pricesGroup.is_used_for_pricelist
            })

            if (!pricesGroup.is_used_for_pricelist) {
              this.hasUnusedPricesGroups = true
            }
          }
        }

        if (null === this.hasUnusedPricesGroups) {
          this.hasUnusedPricesGroups = false
        }

        if (null === this.pricelistBranchOffice) {
          this.formBuilder.addFieldSetField(
              'branch_office_is_not_set_info',
              'branch_office_is_not_set_info',
              {
                slot: 'branch_office_is_not_set_info',
                type: 'infoblock'
              }
          )
        } else if (!this.hasPricesGroups) {
          this.formBuilder.addFieldSetField(
              'branch_office_no_prices_groups_info',
              'branch_office_no_prices_groups_info',
              {
                slot: 'branch_office_no_prices_groups_info',
                type: 'infoblock'
              }
          )
        } else if (this.isCreate && !this.hasUnusedPricesGroups) {
          this.formBuilder.addFieldSetField(
              'pricelist_no_unsed_prices_groups_info',
              'pricelist_no_unsed_prices_groups_info',
              {
                slot: 'pricelist_no_unsed_prices_groups_info',
                type: 'infoblock'
              }
          )
        }

        const pricelistName = response.data.pricelist_main_info?.name ?? null

        this.breadcrumbsConfig.routes.push({
          name: 'pricelists.current',
          params: { pricelistId: this.pricelistId },
          title: pricelistName ? `Прайс-лист «${pricelistName}»` : 'Новый прайс-лист'
        })

        this.breadcrumbsConfig.routes.push({
          name: 'pricelists.current.markups_configs.all',
          params: { pricelistId: this.pricelistId },
          title: 'Наценки'
        })
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/available-prices-groups`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    if (!this.isCreate) {
      this.$emit('startLoading')
    }
  },
  mounted() {
    // Fetch prices groups first important for mapping
    this.getPricelistAvailablePricesGroups()
        .then(async () => {
          if (!this.isCreate) {
            await this.getMarkupsGroup()
          }
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>