<template>
  <div class="page-small">
    <h1>
      Маркетплейсы
    </h1>
    <div class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({ name: 'marketplaces.add' })">
          Добавить маркетплейс
        </button>
      </div>
      <div v-for="marketplace in marketplaces ?? []" class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'marketplaces.current.edit', params: { marketplaceId: marketplace.id } })"
                class="icomoon-icon-pencil"
          ></span>
          <span @click="this.$router.push({ name: 'marketplaces.current.delete', params: { marketplaceId: marketplace.id } })"
              class="icomoon-icon-bin"
          ></span>
        </div>
        <h3>
          {{ marketplace.name }}
        </h3>
        <div>
          {{ marketplace.type.label }}
        </div>
      </div>
      <div v-if="null !== marketplaces && !marketplaces.length">
        Маркетплейсы не добавлены
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      marketplaces: null
    }
  },
  methods: {
    async getMarketplaces() {
      const successCallback = (response) => {
        this.marketplaces = response.data?.marketplaces ?? []
      }

      await apiTransport({
        apiMethod: 'marketplaces',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getMarketplaces()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>