<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="formApiMethod"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('trade_name', 'trade_name', {
          label: 'Торговое имя',
          type: 'text',
          required: true,
          max_length: 100
        })
        .addFieldSetField('currency_id', 'currency_id', {
          label: 'Валюта магазина',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ]
        })
    const pageHeader = 'Настройки магазина'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader
      },
      formBuilder: formBuilder,
      formApiMethod: 'shop/main-params/update',
    }
  },
  methods: {
    async getShopMainParams() {
      const successCallback = (response) => {
        if (response.data?.shop_main_params) {
          for (let fieldName in response.data.shop_main_params) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', response.data.shop_main_params[fieldName])
            }
          }

          this.formBuilder.updateFieldParam(
              'currency_id',
              'value',
              response.data.shop_main_params.currency.id
          )
        }
      }

      await apiTransport({
        apiMethod: 'shop/main-params',
        successCallback: successCallback
      })
    },
    async getCurrencies() {
      const successCallback = (response) => {
        if (response.data?.currencies) {
          for (let key in response.data.currencies) {
            this.formBuilder.addSelectOption('currency_id', {
              value: response.data.currencies[key]?.id,
              text: response.data.currencies[key]?.full_name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'currencies',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch currencies first important for mapping
    this.getCurrencies()
        .then(async () => {
          await this.getShopMainParams()
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>