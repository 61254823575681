<template>
  <form @submit.prevent="submitMethod">
    <fieldset v-for="(fieldSet, fieldSetName) in formFieldSets"
              class="form-fieldset"
              :class="{
                'form-fieldset-list': !!fieldSet?.isListType,
                'form-fieldset-hidden': !!fieldSet?.hidden
              }"
    >
      <legend v-if="fieldSet?.label">
        {{ fieldSet.label }}
      </legend>

      <div v-for="(field, fieldName) in fieldSet.fields"
           class="form-field-wrap"
           :class="{
              'form-field-hidden': !!field?.hidden,
              'form-field-error': field?.errors && field?.errors.length,
              'form-field-wrap-filled': field?.value !== '' && field?.value !== undefined,
              'form-field-wrap-required': !!field?.required
           }"
      >
        <div v-if="!!fieldSet?.isListType"
             class="form-remove-field-from-fieldset-wrap icon-buttons"
        >
          <span class="icomoon-icon-bin"
            @click="field.removeFieldFromFieldSet"
          ></span>
        </div>

        <TextFieldsLine v-if="'text_fields_line' === field.type"
                        :fields="field.fields"
        ></TextFieldsLine>

        <TextField v-if="'text' === field.type || 'number' === field.type || 'password' === field.type"
                  :fieldName="fieldName"
                  :field="field"
                  v-model:value="field.value"
        ></TextField>

        <TextareaField v-if="'textarea' === field.type"
                  :fieldName="fieldName"
                  :field="field"
                  v-model:value="field.value"
        ></TextareaField>

        <SelectList v-if="'select' === field.type"
                    :field="field"
                    v-model:value="field.value"
        ></SelectList>

        <div v-if="'label' === field.type"
             class="fields-bulk-label"
        >
          {{ field.label }}
        </div>

        <div v-if="'infoblock' === field.type && field?.slot"
             class="info-block"
        >
          <slot :name="field.slot"></slot>
        </div>

        <div v-if="'htmlblock' === field.type && field?.slot">
          <slot :name="field.slot"></slot>
        </div>

        <div v-if="'radio' === field.type"
             class="radio-buttons"
        >
          <div class="radio-buttons-label">
            {{ field.label }}
          </div>
          <label v-for="option in field?.options ?? []">
            <input type="radio"
                   :value="option.value"
                   :name="fieldName"
                   :disabled="!!field?.disabled"
                   v-model="field.value"
            />
            <div class="radio-circle"></div>
            <div class="radio-text">
              {{ option.text }}
            </div>
          </label>
        </div>

        <Switcher v-if="'switcher' === field.type"
                  :label="field.label"
                  :description="field?.description ?? null"
                  :disabled="!!field?.disabled"
                  :disabledEnabled="!!field?.disabledEnabled"
                  v-model:isEnabled="field.isEnabled"
                  v-model:value="field.value"
        ></Switcher>

        <div v-if="field?.errors && field.errors.length" class="form-field-errors">
          <div v-for="error in field.errors">
            {{ error.message }}
          </div>
        </div>
      </div>

      <button v-if="fieldSet?.buttonAfter"
              class="sm-button"
              type="button"
              @click="fieldSet.buttonAfter.click"
      >
        {{ fieldSet.buttonAfter.buttonText }}
      </button>
    </fieldset>
    <div v-if="!isSubmitButtonDisabled" class="buttons-wrap">
      <button type="submit"
        :class="{
          'btn-pl-loader': this.isSubmitHandling,
          'btn-disabled': this.formBuilder.submitDisabled
        }"
      >
        {{ submitButtonText }}
      </button>
      <button v-if="cancelCallback" @click="cancelCallback">
        {{ cancelButtonText }}
      </button>
    </div>
  </form>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Switcher from "@/components/UI/Form/Switcher.vue";
import TextField from "@/components/UI/Form/TextField.vue";
import SelectList from "@/components/UI/Form/SelectList.vue";
import TextareaField from "@/components/UI/Form/TextareaField.vue";
import TextFieldsLine from "@/components/UI/Form/TextFieldsLine.vue";

export default {
  components: {TextFieldsLine, TextareaField, SelectList, TextField, Switcher},
  props: {
    apiMethod: {
      type: String,
      required: true
    },
    formBuilder: {
      type: FormBuilder,
      required: true
    },
    submitButtonText: {
      type: String,
      default: 'Сохранить'
    },
    cancelButtonText: {
      type: String,
      default: 'Отмена'
    },
    isSubmitButtonDisabled: {
      type: Boolean,
      default: false
    },
    onCancel: Function,
    onSubmit: Function,
    onUnauthorized: Function,
    onSuccess: Function,
    onError: Function
  },
  data() {
    return {
      formFieldSets: this.formBuilder.build().getForm(),
      isSubmitHandling: false,
      submitMethod: this.onSubmit ?? this.submit,
      successCallback: this.onSuccess ?? null,
      errorCallback: this.onError ?? null,
      unauthorizedCallback: this.onUnauthorized ?? null,
      cancelCallback: this.onCancel ?? null
    }
  },
  methods: {
    async submit() {
      if (this.formBuilder.isSubmitDisabled()) {
        return
      }

      const successCallback = (response) => {
        this.formBuilder.cleanAllErrors() // clean old errors

        if (null !== this.successCallback) {
          this.successCallback(response)
        }
      }

      const errorCallback = (err) => {
        if (err.response.data?.errors) {
          this.formBuilder.cleanAllErrors() // clean old errors

          for (let fieldName in err.response.data.errors) {
            if (this.formBuilder.hasFieldForErrors(fieldName)) {
              this.formBuilder.setFieldErrors(fieldName, err.response.data.errors[fieldName])
            }
          }
        }

        if (null !== this.errorCallback) {
          this.errorCallback(err.response)
        }
      }

      const finallyCallback = () => {
        this.isSubmitHandling = false
      }

      const payload = this.formBuilder.getPayload()

      this.isSubmitHandling = true

      await apiTransport({
        apiMethod: this.apiMethod,
        httpMethod: 'post',
        payload: payload,
        contentType: this.formBuilder.getEnctype(),
        successCallback: successCallback,
        errorCallback: errorCallback,
        finallyCallback: finallyCallback,
        unauthorizedCallback: this.unauthorizedCallback
      })
    }
  },
  watch: {
    formFieldSets: {
      handler() {
        this.formBuilder.build()
      },
      deep: true
    }
  }
}
</script>