<template>
  <div v-if="null !== isActivated" class="page-center ta-c">
    <div v-if="true === isActivated">
      <h1>
        Аккаунт успешно активирован!
      </h1>
      <div class="mb-primary">
        Теперь Вы можете войти в панель управления
      </div>
      <button @click="this.$router.push({ name: 'sign_in' })">
        Войти
      </button>
    </div>
    <div v-else-if="false === isActivated">
      <h1>
        Сбой активации
      </h1>
      <div>
        Что-то пошло не так :(
      </div>
    </div>
  </div>
</template>

<script>

import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      isActivated: null
    }
  },
  methods: {
    async activate() {
      this.isCreateHandling = true
      const successCallback = (response) => {
        this.isActivated = true
      }

      const errorCallback = (err) => {
        this.isActivated = false
      }

      await apiTransport({
        apiMethod: 'confirm/new-account',
        httpMethod: 'post',
        payload: {
          key: this.$route.query?.key ?? '',
          email: this.$route.query?.email ?? ''
        },
        successCallback: successCallback,
        errorCallback: errorCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.activate()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>