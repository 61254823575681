<template>
  <form @submit.prevent="uploadFile">
    <div class="form-field-wrap">
      <input style="display: none;"
             :id="'pricelist_file'"
             :type="'file'"
             :accept="accept.join(',')"
             :ref="'pricelistFile'"
             @change="updateUploadFileInfo($event)"
      />
      <div>
        <div v-if="null !== selectedFile" class="mb-primary">
          {{ selectedFile.name }}
        </div>
        <div v-else-if="!isSubmitHandling && 0 === updateState?.status" class="mb-primary">
          Выберите новый прайс-лист для загрузки
        </div>
        <div v-else-if="!isSubmitHandling && 0 !== updateState?.status" class="mb-primary">
          {{ updateState.text }}
        </div>
        <div class="file-upload-progress mb-primary"
             :class="{
                'file-upload-progress-processing': 10 === updateState?.status
             }"
        >
          <div class="file-upload-progress-bar"
               :class="{
                  'file-upload-progress-bar-success': 100 === fileUploadProgressText
               }"
               :style="{
                  width: `${fileUploadProgress}%`
               }"
          >
          </div>
          <div v-if="isSubmitHandling || fileUploadProgressText > 1" class="file-upload-progress-info">
            {{ fileUploadProgressText }}%
          </div>
          <div v-else-if="null !== selectedFile" class="file-upload-progress-info">
            Нажмите «Загрузить»
          </div>
          <div v-else-if="3 === updateState?.status" class="file-upload-progress-info">
            Ожидаем очереди
          </div>
          <div v-else-if="10 === updateState?.status" class="file-upload-progress-info">
            Читаем прайс-лист и загружаем предложения
          </div>
          <div v-else class="file-upload-progress-info">
            Ничего не происходит
          </div>
        </div>
      </div>
      <div class="buttons-wrap">
        <button type="button"
                @click="!isUploadDisabled && !isSubmitHandling && uploadFileClick()"
                :class="{
                  'btn-disabled': isUploadDisabled || isSubmitHandling
                }"
        >
          {{ null === selectedFile ? noFileSelectedButtomText : fileSelectedButtomText }}
        </button>
        <button v-if="null !== selectedFile && !isUploadDisabled"
                type="submit"
                :class="{
                  'btn-disabled': isSubmitHandling
                }"
        >
          Загрузить
        </button>
        <button v-if="null !== selectedFile && !isSubmitHandling && !isUploadDisabled"
                type="button"
                @click="cleanField()"
        >
          Отмена
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  props: {
    pricelistId: null,
    pricelistUpdateState: null,
    isPricelistDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateState: this.pricelistUpdateState,
      noFileSelectedButtomText: 'Выбрать прайс-лист',
      fileSelectedButtomText: 'Выбрать другой прайс-лист',
      accept: [
        '.csv',
        '.txt',
        'text/plain',
        '.xlsm',
        '.xls',
        '.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12'
      ],
      selectedFile: null,
      isSubmitHandling: false,
      isSubmitDisabled: false,
      isUploadDisabled: this.isPricelistDisabled || 0 !== this.pricelistUpdateState?.status,
      fileUploadProgress: 0,
      fileUploadProgressText: 0
    }
  },
  methods: {
    updateUploadFileInfo(event) {
      if (!event.target.files.length) {
        this.cleanField()
      } else {
        const file = event.target.files[0]

        this.selectedFile = {
          file: file,
          name: file.name
        }
      }
    },
    uploadFileClick() {
      this.$refs.pricelistFile.click()
    },
    cleanField() {
      this.fileUploadProgress = 0
      this.fileUploadProgressText = 0
      this.selectedFile = null

      // Reset file in input
      this.$refs.pricelistFile.value = null
    },
    async uploadFile() {
      if (this.isSubmitDisabled || null === this.selectedFile) {
        return
      }

      let progressCounterId
      let isUploadedSuccess = false

      const successCallback = (response) => {
        this.updateState = response.data.new_update_state
        this.isUploadDisabled = 0 !== response.data.new_update_state.status
        isUploadedSuccess = true
      }

      const errorCallback = (err) => {
        clearInterval(progressCounterId)
        this.cleanField()
      }

      const finallyCallback = () => {
        this.isSubmitHandling = false
      }

      const payload = {
        pricelist_file: this.selectedFile.file
      }

      this.isSubmitHandling = true

      // Animate percents count text. On success animate counter up to 100 and stop animation
      progressCounterId = setInterval(() => {
        if ((isUploadedSuccess && this.fileUploadProgressText < 100)
            || this.fileUploadProgress > this.fileUploadProgressText
        ) {
          this.fileUploadProgressText++
        } else if (isUploadedSuccess && 100 === this.fileUploadProgressText) {
          clearInterval(progressCounterId)

          // Wait 1.1 seconds to 1 seconds animation complete
          setTimeout(() => {
            this.cleanField()
          }, 1100)
        }
      }, 10)

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/upload`,
        httpMethod: 'post',
        payload: payload,
        contentType: 'multipart/form-data',
        successCallback: successCallback,
        finallyCallback: finallyCallback,
        onUploadProgress: (progressEvent) => {
          this.fileUploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
      })
    }
  },
  watch: {
    pricelistUpdateState(newValue) {
      this.updateState = newValue
      this.isUploadDisabled = 0 !== newValue.status
    }
  }
}
</script>

<style scoped>
.file-upload-progress {
  height: 28px;
  overflow: hidden;
  border-radius: var(--secondary-br);
  background-color: var(--light-grey-color);
  position: relative;
}
.file-upload-progress-bar {
  width: 0;
  height: 100%;
  background: #333;
  transition: all 200ms linear;
}
.file-upload-progress-bar-success {
  width: 100% !important;
  animation: file-upload-progress-bar-success-animate 1s ease-out forwards;
}
@keyframes file-upload-progress-bar-success-animate {
  20% {opacity: 1;}
  100% {opacity: 0;}
  100% {width: 0;}
}
.file-upload-progress-info {
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: #fff;
}
.file-upload-progress-processing::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #9498b5;
  z-index: 1;

  transition: all 250ms ease;

  background-size: 30px 30px;
  background-image: -webkit-gradient(linear, left top, right bottom,
    color-stop(.25, rgba(255, 255, 255, .15)), color-stop(.25, transparent),
    color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .15)),
    color-stop(.75, rgba(255, 255, 255, .15)), color-stop(.75, transparent),
    to(transparent));
  background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%,
    transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
    transparent 75%, transparent);
  background-image: -moz-linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%,
    transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
    transparent 75%, transparent);
  background-image: -ms-linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%,
    transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
    transparent 75%, transparent);
  background-image: -o-linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%,
    transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
    transparent 75%, transparent);
  background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%,
    transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
    transparent 75%, transparent);

  animation: progress-animate-stripes 4s linear infinite;
}
@keyframes progress-animate-stripes {
  0% {background-position: 0 0;} 100% {background-position: 60px 0;}
}
.file-upload-progress-processing::after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  z-index: 2;
  animation: progress-animate-shine 4s ease-out infinite;
}
@keyframes progress-animate-shine {
  0% {opacity: 0; width: 0;}
  50% {opacity: 0.5;}
  100% {opacity: 0; width: 95%;}
}
</style>