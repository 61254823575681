<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-small">
    <div v-if="null !== pricelist" class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({
          name: 'events.all',
          query: {
            entity: 'pricelist',
            entity_id: pricelist.id
          }
        })">
          Журнал действий
        </button>
      </div>

      <div v-if="!isFullyCreated" class="error-color">
        Настройка не завершена – загрузка невозможна
      </div>

      <div v-else-if="pricelist.main_info.is_disabled" class="warning-color">
        Прайс-лист выключен – загрузка невозможна
      </div>

      <div v-else class="info-block">
        <h3>
          <span class="icomoon-icon-stack"></span> Содержимое
        </h3>
        <UploadPricelistBlock :pricelist-id="pricelistId"
                              :pricelist-update-state="pricelistContent.update_state"
                              :is-pricelist-disabled="pricelist.main_info.is_disabled"
        ></UploadPricelistBlock>
        <div class="last-event-info-container">
          <div v-if="null === lastUpdateContentActionEventId"
               class="last-event-info"
          >
            Прайс-лист ни разу не загружался
          </div>
          <div v-else class="last-event-info"
               :class="{
                 'last-event-info-success': isLastUpdateContentActionEventSuccess,
                 'last-event-info-error': !isLastUpdateContentActionEventSuccess
               }"
          >
            <div v-if="isLastUpdateContentActionEventSuccess">
              Обновлен <b>{{ lastUpdateContentActionEventDatetime?.formatted }}</b>
            </div>
            <div v-else>
              Ошибка обновления <b>{{ lastUpdateContentActionEventDatetime?.formatted }}</b>
            </div>
            <button class="xs-button"
                    @click="this.$router.push({
                      name: 'events.current',
                      params: {
                        eventId: lastUpdateContentActionEventId
                      }
                    })"
            >
              Отчет
            </button>
          </div>
        </div>
        <div v-if="null !== pricelistContent.updated_at">
          <span class="grey-color">
            Загружено позиций:
          </span> <b>{{ pricelistContent.count }}</b> <span class="grey-color">
          – <router-link :to="{
                           name: 'pricelists.current.content_preview',
                           params: {
                             pricelistId: pricelist.id
                           }
                         }"
          >
              предпросмотр содержимого
          </router-link>
          </span>
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasMainInfo
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.main', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-file-text2"></span> Основная информация
        </h3>
        <div v-if="!pricelist.main_info.is_disabled">
          <span class="icomoon-icon-switch success-color"></span> Прайс-лист включен
        </div>
        <div v-if="pricelist.main_info.is_disabled">
          <span class="icomoon-icon-switch error-color"></span> Прайс-лист выключен
        </div>
        <div>
          <span class="grey-color">Поставщик:</span> {{ supplierName }}
          <br />
          <span class="grey-color">Филиал:</span> {{ branchOfficeName }}
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasMarkupsConfigs
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.markups_configs.all', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-price-tags"></span> Наценки
        </h3>
        <div v-if="hasMarkupsConfigs">
          Установлены наценки для {{ pricelist.markups_configs_count }} групп цен
        </div>
        <div v-else class="error-color">
          Наценки не настроены
        </div>
      </div>

      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.delivery', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-clock"></span> Поставка
        </h3>
        <div>
          <span class="grey-color">Срок поставки:</span> {{ pricelist.delivery.user_format }}
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasUploadFileConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.upload_file_config', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-table2"></span> Столбцы
        </h3>
        <div v-if="hasUploadFileConfig">
          Настроено
        </div>
        <div v-else class="error-color">
          Столбцы не настроены
        </div>
      </div>

      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.filters', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-filter"></span> Фильтрация и автозамена
        </h3>
        <div>
          <span class="grey-color">Цены:</span> от {{ pricelist.filters.min_price }} до {{ pricelist.filters.max_price }}
        </div>
      </div>

      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.currency', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-sphere"></span> Валюта
        </h3>
        <div v-if="pricelist.currency?.in_currency && pricelist.currency.in_currency.id !== pricelist.currency.out_currency.id">
          <span class="grey-color">Конвертация:</span> по курсу ЦБ
          <br />
          {{ pricelist.currency.in_currency.full_user_name }} → {{ pricelist.currency.out_currency.full_user_name }}
        </div>
        <div v-if="pricelist.currency?.in_currency && pricelist.currency.in_currency.id === pricelist.currency.out_currency.id">
          <span class="grey-color">Конвертация:</span> 1 к 1
        </div>
        <div v-if="pricelist.currency?.custom_currency_coef">
          <span class="grey-color">Конвертация:</span> по коэффициенту
          <br />
          Цена × {{ pricelist.currency.custom_currency_coef }}
        </div>
        <div v-if="!pricelist.currency?.in_currency && !pricelist.currency?.custom_currency_coef">
          <span class="grey-color">Конвертация:</span> не настроена
        </div>
      </div>

      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.download_config', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-download"></span> Автозагрузка
        </h3>
        <div v-if="1 === pricelist.download_type">
          Автозагрузка с почтового ящика
        </div>
        <div v-else-if="2 === pricelist.download_type">
          Автозагрузка по ссылке
        </div>
        <div v-else-if="3 === pricelist.download_type">
          Автозагрузка с FTP-сервера
        </div>
        <div v-else>
          Автозагрузка не настроена
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import UploadPricelistBlock from "@/pages/Platform/Pricelists/UploadPricelistBlock.vue";
import {HttpStatusCode} from "axios";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import "@/pages/Platform/Events/last_event.css";

export default {
  components: {Breadcrumbs, UploadPricelistBlock},
  data() {
    const pricelistId = this.$route.params?.pricelistId ?? null

    return {
      pricelistId: pricelistId,
      pricelist: null,
      pricelistContent: null,
      lastUpdateContentActionEventId: null,
      isLastUpdateContentActionEventSuccess: false,
      lastUpdateContentActionEventDatetime: null,
      isFullyCreated: false,
      pageHeader: '',
      breadcrumbsConfig: {
        currentTitle: '',
        routes: [
          {
            name: 'pricelists.all',
            title: 'Прайс-листы'
          }
        ]
      },
      supplierName: 'не указан',
      branchOfficeName: 'не указан',
      hasMainInfo: false,
      hasMarkupsConfigs: false,
      hasUploadFileConfig: false,
      isPricelistContentPollingActive: false
    }
  },
  methods: {
    async getPricelistSummary() {
      const successCallback = (response) => {
        this.pricelist = response.data.pricelist_summary

        this.pricelistContent = this.pricelist.content

        this.isFullyCreated = this.pricelist.is_fully_created
        this.hasMainInfo = this.pricelist.has_main_info
        this.hasMarkupsConfigs = this.pricelist.has_markups_configs
        this.hasUploadFileConfig = this.pricelist.has_upload_file_config

        if (this.hasMainInfo) {
          this.supplierName = this.pricelist.main_info.supplier_name
          this.branchOfficeName = this.pricelist.main_info.branch_office.name
        }

        this.pageHeader = this.pricelist?.main_info?.name ? `Прайс-лист «${this.pricelist.main_info.name}»` : 'Новый прайс-лист'
        this.breadcrumbsConfig.currentTitle = this.pageHeader

        this.setLastUpdateContentActionData(this.pricelist.last_update_content_action_event)

        // Enable polling, if pricelist enabled
        this.isPricelistContentPollingActive = this.isFullyCreated && !this.pricelist.is_disabled

        this.updatePricelistSummaryContent()
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/summary`,
        successCallback: successCallback
      })
    },
    async getPricelistSummaryContentUpdate() {
      const successCallback = (response) => {
        this.pricelistContent = response.data?.pricelist_summary_content
        this.setLastUpdateContentActionData(response.data?.last_update_content_action_event)

        if (this.isPricelistContentPollingActive && this.isFullyCreated && !this.pricelist.main_info.is_disabled) {
          this.updatePricelistSummaryContent()
        }
      }

      // Don't use finallyCallback for check JWT expired 401 response
      const errorCallback = (err) => {
        // Don't call on 401 response! It prevents to multiply timeout calls
        if (this.isPricelistContentPollingActive && HttpStatusCode.Unauthorized !== err.status) {
          this.updatePricelistSummaryContent()
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/summary/content`,
        successCallback: successCallback,
        errorCallback: errorCallback
      })
    },
    setLastUpdateContentActionData(event) {
      this.lastUpdateContentActionEventId = event?.id ?? null
      this.isLastUpdateContentActionEventSuccess = 'success' === event?.status
      this.lastUpdateContentActionEventDatetime = event?.datetime ?? null
    },
    updatePricelistSummaryContent() {
      setTimeout(() => {
        this.getPricelistSummaryContentUpdate()
      }, 5000)
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelistSummary()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ],
  beforeUnmount() {
    // Disable polling is important
    this.isPricelistContentPollingActive = false
  }
}
</script>