<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-small">
    <div class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({ name: 'users.add' })">
          Добавить пользователя
        </button>
      </div>
      <div v-for="user in users ?? []" class="info-block">
        <div v-if="currentUserId === user.id"
             class="is-current-user-info"
        >
          это Вы
        </div>
        <div v-else
             class="icon-buttons"
        >
          <span @click="this.$router.push({ name: 'users.current.edit', params: { userId: user.id } })"
                class="icomoon-icon-pencil"
          ></span>
          <span @click="this.$router.push({ name: 'users.current.delete', params: { userId: user.id } })"
              class="icomoon-icon-bin"
          ></span>
        </div>
        <h3 v-if="user.name">
          {{ user.name }} {{ user.middle_name }} {{ user.last_name }}
        </h3>
        <h3 v-else>
          ФИО не заполнено
        </h3>
        <div>
          {{ user.email }}
        </div>
        <div>
          <router-link :to="{
              name: 'events.all',
              query: {
                user_id: user.id
              }
            }">
            Журнал действий
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs},
  data() {
    const pageHeader = 'Пользователи'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader
      },
      currentUserId: this.$store.getters.auth.userId,
      users: null
    }
  },
  methods: {
    async getUsers() {
      const successCallback = (response) => {
        this.users = response.data?.users ?? []
      }

      await apiTransport({
        apiMethod: 'users',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getUsers()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>

<style scoped>
.is-current-user-info {
  color: var(--grey-color);
  position: absolute;
  top: var(--primary-gap);
  right: var(--primary-gap);
}
</style>