<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-small">
    <div class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({ name: 'branchoffices.add' })">
          Добавить филиал
        </button>
      </div>
      <div v-for="branchOffice in branchOffices ?? []" class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'branchoffices.current.edit', params: { branchOfficeId: branchOffice.id } })"
                class="icomoon-icon-pencil"
          ></span>
          <span @click="this.$router.push({ name: 'branchoffices.current.delete', params: { branchOfficeId: branchOffice.id } })"
              class="icomoon-icon-bin"
          ></span>
        </div>
        <h3>
          {{ branchOffice.name }}
        </h3>
        <div v-if="branchOffice.company">
          {{ branchOffice.company.company_user_type }} {{ branchOffice.company.company_name }}
        </div>
        <div v-else>
          Компания не привязана
        </div>
      </div>
      <div v-if="null !== branchOffices && !branchOffices.length">
        Филиалы не добавлены
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs},
  data() {
    const pageHeader = 'Филиалы'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader
      },
      branchOffices: null
    }
  },
  methods: {
    async getBranchOffices() {
      const successCallback = (response) => {
        this.branchOffices = response.data?.branch_offices ?? []
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getBranchOffices()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>