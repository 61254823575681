<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:schedule_info>
        <h3>
          <span class="icomoon-icon-info"></span> Информация
        </h3>
        <div>
          Указываемое время – это время <b>запуска</b> генерации. Соответственно, обновление фида будет всегда чуть позже.
        </div>
        <div>
          При внесении времени в расписание принимайте во внимание время обновления прайс-листов с почты.
        </div>
        <div>
          <b>Пример частного случая:</b>
        </div>
        <ul>
          <li>
            фид генерируется на основе 3-х прайс-листов, автоматически загружаемых с Вашего почтового ящика;
          </li>
          <li>
            первый прайс-лист обычно <b>загружается в систему</b> к 9:20, второй к 9:50, а третий к 10:00.
          </li>
        </ul>
        <div>
          В данном случае рекомендуем установить время генерации на 11:00, чтобы гарантировать, что в генерации фида в это время будут участвовать самые свежие прайс-листы.
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const feedId = this.$route.params.feedId

    const goToFeedSummary = () => {
      this.$router.push({ name: 'feeds.current', params: { feedId: feedId } })
    }

    const successCallback = (response) => {
      goToFeedSummary()
    }
    const cancelCallback = (response) => {
      goToFeedSummary()
    }

    const addScheduleConfig = () => {
      this.formBuilder.addFieldSetFieldsLine(
          'days_configs',
          'days_configs',
          this.createScheduleConfigFieldsOfLine(),
          true
      )
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('schedule_info', 'schedule_info', {
          slot: 'schedule_info',
          type: 'infoblock'
        })
        .addFieldSetField('days_configs_label', 'days_configs_label', {
          label: 'Настройте расписание генераций фида *',
          type: 'label',
          mapErrorsOfFieldNames: ['days_configs']
        })
        .addFieldSetParams('days_configs', {
          buttonAfter: {
            buttonText: 'Добавить день недели и время',
            click: addScheduleConfig
          }
        })

    const pageHeader = 'Расписание генерации'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'feeds.all',
            title: 'Фиды'
          }
        ]
      },
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `feeds/${feedId}/schedule/update`,
      feedId: feedId
    }
  },
  methods: {
    // Сallback for prevent using link to the same object
    createScheduleConfigFieldsOfLine(weekDayNumber, hour, minute) {
      const hourOptions = [
        {
          text: 'Выбрать..',
          hidden: true
        }
      ]

      for (let i = 0; i < 24; i++) {
        hourOptions.push({
          value: i,
          text: ('0' + i).slice(-2)
        })
      }

      return {
        type: 'text_fields_line',
        listFieldName: 'days_configs',
        fields: [
          {
            fieldName: 'week_day_number',
            label: 'День недели',
            type: 'select',
            required: true,
            value: weekDayNumber ?? '',
            options: [
              {
                text: 'Выбрать..',
                hidden: true
              },
              {
                value: 1,
                text: 'Понедельник'
              },
              {
                value: 2,
                text: 'Вторник'
              },
              {
                value: 3,
                text: 'Среда'
              },
              {
                value: 4,
                text: 'Четверг'
              },
              {
                value: 5,
                text: 'Пятница'
              },
              {
                value: 6,
                text: 'Суббота'
              },
              {
                value: 7,
                text: 'Воскресенье'
              }
            ]
          },
          {
            fieldName: 'hour',
            label: 'Час',
            type: 'select',
            required: true,
            value: hour ?? '',
            options: hourOptions
          },
          {
            fieldName: 'minute',
            label: 'Минута',
            type: 'select',
            required: true,
            value: minute ?? '',
            options: [
              {
                text: 'Выбрать..',
                hidden: true
              },
              {
                value: 0,
                text: '00'
              },
              {
                value: 30,
                text: '30'
              }
            ]
          }
        ]
      }
    },
    async getFeedSchedule() {
      const successCallback = (response) => {
        if (response.data?.feed_schedule) {
          const scheduleDays = response.data.feed_schedule.days_configs

          for (let key in scheduleDays) {
            let configFieldsOfLine = this.createScheduleConfigFieldsOfLine(
                scheduleDays[key]?.week_day_number,
                scheduleDays[key]?.hour,
                scheduleDays[key]?.minute
            )

            this.formBuilder.addFieldSetFieldsLine(
                'days_configs',
                'days_configs',
                configFieldsOfLine,
                true
            )
          }
        }

        const feedName = response.data.feed_main_info.name

        this.breadcrumbsConfig.routes.push({
          name: 'feeds.current',
          params: { feedId: this.feedId },
          title: feedName ? `Фид «${feedName}»` : 'Новый фид'
        })
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/schedule`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getFeedSchedule()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>