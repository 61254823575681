<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const emailProfileId = this.$route.params?.emailProfileId ?? null
    const isCreate = null === emailProfileId

    const goToEmailProfilesList = () => {
      this.$router.push({ name: 'emailprofiles.all' })
    }
    const successCallback = (response) => {
      goToEmailProfilesList()
    }
    const cancelCallback = (response) => {
      goToEmailProfilesList()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('main', 'name', {
          label: 'Название почтового профиля',
          type: 'text',
          required: true
        })
        .addFieldSetField('main', 'email', {
          label: 'E-mail',
          type: 'text',
          required: true
        })
        .addFieldSetField('main', 'password', {
          label: 'Пароль от почтового ящика',
          type: 'text',
          required: true
        })
        .addFieldSetField('main', 'email_client_id', {
          label: 'Почтовый клиент',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ]
        })
        .addFieldSetField('branch_offices', 'branch_offices_label', {
          label: 'Филиалы, для которых доступен почтовый профиль',
          type: 'label',
          required: true
        })

    const pageHeader = isCreate ? 'Новый почтовый профиль' : 'Редактирование почтового профиля'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'emailprofiles.all',
            title: 'Почтовые профили'
          }
        ]
      },
      isCreate: isCreate,
      submitButtonText: isCreate ? 'Создать' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      emailProfileId: emailProfileId,
      apiMethod: isCreate ? 'email-profiles/create' : `email-profiles/${emailProfileId}/update`,
    }
  },
  methods: {
    async getEmailProfile() {
      const successCallback = (response) => {
        if (response.data?.email_profile) {
          for (let fieldName in response.data.email_profile) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', response.data.email_profile[fieldName])
            }
          }

          // Map branch offices
          for (let branchOfficeKey in response.data.email_profile.branch_offices) {
            let branchOfficeFieldName = 'branch_office_' + response.data.email_profile.branch_offices[branchOfficeKey].id

            if (this.formBuilder.hasField(branchOfficeFieldName)) {
              this.formBuilder.updateFieldParam(branchOfficeFieldName, 'isEnabled', true)
            }
          }

          this.formBuilder.updateFieldParam(
              'email_client_id',
              'value',
              response.data.email_profile?.email_client?.id
          )
        }
      }

      await apiTransport({
        apiMethod: `email-profiles/${this.emailProfileId}`,
        successCallback: successCallback
      })
    },
    async getBranchOffices() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]
            let branchOfficeFieldName = `branch_office_${branchOffice.id}`
            let companyFullName = null
            if (branchOffice?.company) {
              companyFullName = branchOffice.company?.company_user_type + ' ' + branchOffice.company?.company_name
            }

            this.formBuilder.addFieldSetField('branch_offices', branchOfficeFieldName, {
              label: branchOffice?.name,
              description: companyFullName,
              listFieldName: 'branch_offices',
              mapErrorsFieldName: 'branch_offices_label',
              type: 'switcher',
              value: branchOffice.id
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    },
    async getEmailClients() {
      const successCallback = (response) => {
        if (response.data?.email_clients) {
          for (let key in response.data.email_clients) {
            let emailClient = response.data.email_clients[key]

            this.formBuilder.addSelectOption('email_client_id', {
              value: emailClient.id,
              text: emailClient.label
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'email-clients',
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  async mounted() {
    // Fetch email clients and branch offices first important for mapping
    Promise.all([this.getEmailClients(), this.getBranchOffices()])
        .then(async () => {
          if (!this.isCreate) {
            await this.getEmailProfile()
          }
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>