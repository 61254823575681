<template>
  <div v-if="null !== isChanged" class="page-center ta-c">
    <div v-if="true === isChanged">
      <h1>
        E-mail успешно изменен!
      </h1>
      <div class="mb-primary">
        Теперь необходимо войти в панель управления с новым E-mail
      </div>
      <button @click="this.$router.push({ name: 'sign_in' })">
        Войти
      </button>
    </div>
    <div v-else-if="false === isChanged">
      <h1>
        Что-то пошло не так :(
      </h1>
      <div>
        Вероятно, время жизни ссылки изменения E-mail истекло..
      </div>
    </div>
  </div>
</template>

<script>

import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      isChanged: null
    }
  },
  methods: {
    async activate() {
      this.isCreateHandling = true
      const successCallback = (response) => {
        this.isChanged = true
        this.$store.commit('resetAuth') // old authorization params is invalid now
      }

      const errorCallback = (err) => {
        this.isChanged = false
      }

      await apiTransport({
        apiMethod: 'confirm/change-email',
        httpMethod: 'post',
        payload: {
          user_id: this.$route.query?.user_id ?? '',
          key: this.$route.query?.key ?? '',
          new_email: this.$route.query?.new_email ?? ''
        },
        successCallback: successCallback,
        errorCallback: errorCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.activate()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>