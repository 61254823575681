<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:branch_office_is_not_set_info>
        <div>
          <b>Для фида не указан филиал</b>
        </div>
        <div>
          Группы цен для прайс-листа могут быть выбраны только из того же филиала, что и прайс-лист, поэтому сначала необходимо указать филиал прайс-листа.
        </div>
        <div>
          <button @click="this.$router.push({ name: 'feeds.current.edit.main', params: {feedId: feedId} })">
            Указать филиал фида
          </button>
        </div>
      </template>
      <template v-slot:branch_office_no_prices_groups_info>
        <div>
          <b>Для филиала фида не настроено ни одной группы цен</b>
        </div>
        <div>
          Группы цен для фида могут быть выбраны только из того же филиала, что и фид, поэтому сначала добавьте группы цен для филиала «<b>{{ feedBranchOffice.name }}</b>», к которому привязан данный фид.
        </div>
        <div>
          <button @click="this.$router.push({ name: 'pricesgroups.all' })">
            Перейти к группам цен
          </button>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const feedId = this.$route.params.feedId

    const goToFeedSummary = () => {
      this.$router.push({ name: 'feeds.current', params: { feedId: feedId } })
    }

    const successCallback = (response) => {
      goToFeedSummary()
    }
    const cancelCallback = (response) => {
      goToFeedSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .setSubmitDisabledCallback(() => {
          return !this.isEditAllowed()
        })
        .addFieldSetField('offers_config', 'prices_group_id', {
          label: 'Группа цен',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ]
        })
        .addFieldSetField('offers_config', 'min_price', {
          label: 'Добавлять предложения с ценами от',
          type: 'number',
          required: true
        })
        .addFieldSetField('offers_config', 'max_price', {
          label: 'Добавлять предложения с ценами до',
          type: 'number',
          required: true
        })
        .addFieldSetField('offers_config', 'max_count_enabled', {
          label: 'Ограничить количество предложений в фиде',
          type: 'switcher'
        })
        .addFieldSetField('offers_config', 'max_count', {
          label: 'Максимальное количество предложений в фиде *',
          type: 'number',
          isHidden: () => {
            return this.isMaxCountEnabled()
          }
        })
        .addFieldSetField('offers_config', 'duplicates_disabled', {
          label: 'Не добавлять дубликаты',
          description: 'Предложения в фиде без повторов',
          type: 'switcher'
        })
        .addFieldSetField('offers_config', 'duplicates_min_price_enabled', {
          label: 'Добавлять позиции с минимальными ценами',
          description: 'Из повторяющихся предложений будет добавлено самое дешевое',
          type: 'switcher'
        })
        .addFieldSetParams('offers_config', {
          isHidden: () => {
            return !this.isEditAllowed()
          }
        })

    const pageHeader = 'Настройки предложений'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'feeds.all',
            title: 'Фиды'
          }
        ]
      },
      hasPricesGroups: null, // null by default for prevent disable submit before prices groups fetched
      feedBranchOffice: null, // null by default for prevent disable submit before pricelist main info fetched
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `feeds/${feedId}/offers-config/update`,
      feedId: feedId
    }
  },
  methods: {
    isEditAllowed() {
      return null !== this.feedBranchOffice && this.hasPricesGroups
    },
    isMaxCountEnabled() {
      return !this.formBuilder.getFieldValueByFieldName('max_count_enabled')
    },
    async getFeedAvailablePricesGroups() {
      const successCallback = (response) => {
        const pricesGroups = response.data.prices_groups ?? []
        this.feedBranchOffice = response.data.feed_main_info.branch_office

        this.hasPricesGroups = pricesGroups.length > 0

        if (this.hasPricesGroups) {
          for (let key in pricesGroups) {
            let pricesGroup = pricesGroups[key]

            this.formBuilder.addSelectOption('prices_group_id', {
              value: pricesGroup.id,
              text: pricesGroup.name
            })
          }
        }

        if (null === this.feedBranchOffice) {
          this.formBuilder.addFieldSetField(
              'branch_office_is_not_set_info',
              'branch_office_is_not_set_info',
              {
                slot: 'branch_office_is_not_set_info',
                type: 'infoblock'
              }
          )
        } else if (!this.hasPricesGroups) {
          this.formBuilder.addFieldSetField(
              'branch_office_no_prices_groups_info',
              'branch_office_no_prices_groups_info',
              {
                slot: 'branch_office_no_prices_groups_info',
                type: 'infoblock'
              }
          )
        }

        const feedName = response.data.feed_main_info.name

        this.breadcrumbsConfig.routes.push({
          name: 'feeds.current',
          params: { feedId: this.feedId },
          title: feedName ? `Фид «${feedName}»` : 'Новый фид'
        })
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/available-prices-groups`,
        successCallback: successCallback
      })
    },
    async getFeedOffersConfig() {
      const successCallback = (response) => {
        if (response.data?.feed_offers_config) {
          const offersConfig = response.data.feed_offers_config

          for (let fieldName in offersConfig) {
            if (this.formBuilder.hasSwitcherField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'isEnabled', offersConfig[fieldName])
            } else if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', offersConfig[fieldName])
            }
          }

          if (null !== offersConfig.max_count) {
            this.formBuilder.updateFieldParam('max_count_enabled', 'isEnabled', true)
          }

          if (null !== offersConfig.prices_group) {
            this.formBuilder.updateFieldParam('prices_group_id', 'value', offersConfig.prices_group.id)
          }
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/offers-config`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch prices groups first important for mapping
    this.getFeedAvailablePricesGroups()
        .then(async () => {
          await this.getFeedOffersConfig()
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>