<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_email_profile_info>
        <div>
          Вы уверены, что хотите удалить почтовый профиль <b>{{ emailProfileName }}</b>?
        </div>
        <div>
          <b>Внимание!</b> Он будет удален у всех прайс-листов, использующих его для автозагрузки прайс-листов с почты, если таковые имеются. Другими словами, все эти прайс-листы перестанут автоматически загружаться с почты.
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs, Form},
  data() {
    const emailProfileId = parseInt(this.$route.params.emailProfileId)

    const goToEmailProfilesList = () => {
      this.$router.push({ name: 'emailprofiles.all' })
    }
    const successCallback = (response) => {
      goToEmailProfilesList()
    }
    const cancelCallback = (response) => {
      goToEmailProfilesList()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_email_profile_info', 'delete_email_profile_info', {
          slot: 'delete_email_profile_info',
          type: 'infoblock'
        })

    const pageHeader = 'Удаление почтового профиля'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader,
        routes: [
          {
            name: 'emailprofiles.all',
            title: 'Почтовые профили'
          }
        ]
      },
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      emailProfileId: emailProfileId,
      apiMethod: `email-profiles/${emailProfileId}/delete`,
      emailProfileName: ''
    }
  },
  methods: {
    async getEmailProfile() {
      const successCallback = (response) => {
        if (response.data?.email_profile) {
          this.emailProfileName = response.data.email_profile.name
        }
      }

      await apiTransport({
        apiMethod: `email-profiles/${this.emailProfileId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getEmailProfile()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>