<template>
  <h1>
    {{ pageHeader }}
  </h1>
  <breadcrumbs :config="breadcrumbsConfig"></breadcrumbs>
  <div class="page-form">
    <div class="info-blocks">
      <div v-if="null !== activePeriodTill"
           class="info-block"
      >
        <div>
          <span class="grey-color">
            Оплаченный период истекает:
          </span> {{ activePeriodTill }}
        </div>
      </div>
    </div>
    <h2>
      История платежей
    </h2>
    <div class="info-blocks">
      <div v-for="payment in payments"
           class="info-block"
      >
        <h3>
          Платеж
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";

export default {
  components: {Breadcrumbs},
  data() {
    const pageHeader = 'Платежная информация'

    return {
      pageHeader: pageHeader,
      breadcrumbsConfig: {
        currentTitle: pageHeader
      },
      payments: [
        {}, {}, {}
      ],
      activePeriodTill: null
    }
  },
  methods: {
    async getBillingInfo() {
      const successCallback = (response) => {
        if (response.data?.billing_info) {
          this.activePeriodTill = response.data.billing_info.active_period_till
        }
      }

      await apiTransport({
        apiMethod: 'billing/info',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getBillingInfo()
        .then(() => {
          this.$emit('finishLoading')
        })
  },
  emits: [
    'startLoading',
    'finishLoading'
  ]
}
</script>